<template>
  <div class="flex w-full bg-img h-full">
    <!-- eslint-disable-next-line max-len, vue/max-attributes-per-line -->
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="@/assets/images/pages/404.png"
        alt="graphic-404"
        class="mx-auto mb-4 max-w-full" >
      <h1 class="sm:mx-0 mx-4 mb-12 text-5xl">{{ $t(title) }}</h1>
      <p class="sm:mx-0 mx-4 mb-4 sm:mb-16">{{ $t(message) }}</p>
      <vs-button
        size="large"
        to="/">{{ $t(backhome) }}</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  props: {
    title: {
      type: String,
      default: 'error404.title',
    },
    message: {
      type: String,
      default: 'error404.message',
    },
    backhome: {
      type: String,
      default: 'error404.backhome',
    },
  },
};
</script>
